<template>
    <div class="page-brand">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>品牌中心</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="brand-list">
                <div class="item" @click="goPPdetail(item)" v-for="(item,index) in brandList" :key="index">
                    <div class="pic">
                        <img :src="item.logoUrl" alt="">
                    </div>
                    <div class="title">{{ item.name }}</div>
                </div>
            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="[20, 50, 100]"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {brandinfoPage} from "@/api/home";

export default {
    name: "brand",
    data() {
        return {
            current:1,
            size:20,
            total:0,
            brandList:[],

        }
    },
    mounted() {
        this.getbrandinfoPage()
    },
    methods:{
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getbrandinfoPage();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getbrandinfoPage();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getbrandinfoPage();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getbrandinfoPage();
        },

        goPPdetail(item){
            // query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
            this.$router.push({path:'/BrandDetail',query: {id:item.id}})
        },
        getbrandinfoPage(){ // 获取品牌列表
            let param={
                current:this.current,
                size:this.size,
	            isShow:1, // 0查全部  1查让显示的
            }
            brandinfoPage(param).then((res) => {
                if (res.data.code==200) {
                    this.brandList=res.data.data.records
                    this.total=Number(res.data.data.total)
                }

            })
        },

    },
}
</script>

<style scoped>

</style>
